import * as React from "react";

function SvgFormBackground2(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1532.38 858.17"
      {...props}
    >
      <path fill="#fff" d="M0 0h1532.34v857.69H0z" />
      <path
        d="M808.99 857.56c-63.59-120.29-26.17-325.11 184.05-347.81 243.96-26.34 191.77-215.12 496.02-216.97 15.14-.09 29.55.57 43.27 1.96v562.95l-723.34-.13z"
        fill="#c4c4e4"
      />
      <path
        d="M1528.46 613.77c-52.84-27.23-65.13 33.88-149.31 62.46s-135.79 67.27-141.69 143.46l5.29 37.03h147.46c67.33-35.27 118.66-97.58 142.13-150.81v-88.36l-3.88-3.78z"
        fill="#68bc95"
      />
      <path
        d="M1479.11 663.28l-8.97-3.82s16.42-23.1 19.91-49.39c-16.83 5.79-32.07 21.67-54.87 37.84l-43.71 121.82s-100.6-6.65-134.41-12.88c-11.26 17.86-18.11 38.75-20.04 63.67l5.29 37.03h29.87l-9-62.57s19.44 50.71 92.35 38.62c72.91-12.08 111.5-108.32 123.58-170.32z"
        fill="#4ab382"
      />
      <g
        fill="none"
        stroke="#2c2454"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      >
        <path d="M1290.84 857.56c115.51-83.4 233.04-244.94 233.04-244.94M1531.88 690.05l-58.04-13.68M1509 748.19l-80.51-19.18M1355.52 685.54l23.5 95.56M1421.68 838.08l-85.12-17.21" />
      </g>
      <g>
        <path
          d="M1530 737.29s-52.1 2.49-70.75 43.96 25.04 55.09 47.4 33.49c27.35-26.42 23.35-77.45 23.35-77.45z"
          fill="#30bde7"
        />
        <g
          fill="none"
          stroke="#2c2454"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        >
          <path d="M1437.66 857.67c2.78-12.12 12.75-24.86 25.59-38.86 3.73-4.08 7.71-8.27 11.83-12.59 4.55-4.77 9.26-9.69 13.99-14.78 2-2.15 4-4.33 5.99-6.54 3.84-4.25 7.64-8.62 11.34-13.12 8.8-10.7 17.01-22.14 23.61-34.49M1527.6 771.78h-21.2M1518.57 798.52l-29.5-7.08M1482.59 753.58l12.47 31.32M1460.41 778.82l14.67 27.39" />
        </g>
      </g>
      <g>
        <path
          d="M1343.89 602.39s-66.27 21.99-70.86 80.53c-4.6 58.54 51.89 56.61 68.41 20.05 19.14-42.4 2.45-100.58 2.45-100.58z"
          fill="#eb5d79"
        />
        <g
          fill="none"
          stroke="#2c2454"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        >
          <path d="M1343.89 602.39c-21.51 73.98-82.23 126.54-66.15 255.17M1316.58 616.22l7.61 35.11M1349.8 670.26l-33.62-2.62M1310.25 679.69l37.58 3.23M1275.06 670.32l25.37 30.46" />
        </g>
      </g>
      <g>
        <path
          d="M954.5 525.66s62.11 31.9 57.67 90.45c-4.44 58.55-59.96 47.98-70.67 9.31-12.42-44.83 13-99.76 13-99.76z"
          fill="#30bde7"
        />
        <path
          d="M977.7 657.14c16.31.94 32.18-10.83 34.47-41.03 1.59-20.92-5.34-38.41-14.87-52.29-4.35 13.96-12.08 43.17-19.6 93.32z"
          fill="#08a4df"
        />
        <g
          fill="none"
          stroke="#2c2454"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        >
          <path d="M954.5 525.66c9.9 76.4 61.84 137.66 26.22 262.29M979.37 543.52l-12.9 33.52M938.25 591.82l33.63 2.57M975.88 607.21l-37.63-2.58M1012.1 603.35l-29.75 26.21" />
        </g>
        <g>
          <path
            d="M898.23 857.56c-34-78.4-5.73-138.67-33.18-191.9-27.44-53.22 7.35-122.65 70.86-77.39 75.16 53.56 113.46 191.21 109.37 269.29H898.23z"
            fill="#68bc95"
          />
          <path
            d="M1045.28 857.56c3.26-62.18-20.37-162.17-68.11-227.35-4.62 18.76-8.64 39.65-10.71 61.19-6.95 72.43 17.35 141.2 58 116.09l-2.87 50.07h23.69z"
            fill="#4ab382"
          />
          <g
            fill="none"
            stroke="#2c2454"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
          >
            <path d="M873.86 582S982.4 711.49 984.24 857.56M956.09 605.45l-27.92 59.19M969.58 620.39l-30.42 65.6M875.58 697.45l74.36 12.04M881.69 795.48l97.37 8.81M1024.46 725.71l-54.88 38.2" />
          </g>
        </g>
        <g>
          <path
            d="M827.03 697.45s68.12 3.25 92.49 57.47c24.38 54.22-32.74 72.02-61.97 43.78-35.74-34.54-30.52-101.25-30.52-101.25z"
            fill="#eb5d79"
          />
          <g
            fill="none"
            stroke="#2c2454"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
          >
            <path d="M827.03 697.45c37.35 69.92 114.43 117.75 121.28 160.11M830.18 742.54h27.72M841.98 777.5l38.57-9.25M889.02 718.75l-16.3 40.95M918.01 751.74l-19.17 35.82" />
          </g>
        </g>
      </g>
      <g>
        <path
          d="M1102.55 482.46c2.25-30.85 21.56-50.21 50.78-53.3 42.45-4.49 70.07 35.21 66.56 71.93-3.54 36.98-5.95 62.18 5.91 100.76 0 0-109.93 3.95-147.81-9.7 0 0 11.72-13.22 20.79-70.7.26-1.61 3.77-38.99 3.77-38.99z"
          fill="#2c2454"
        />
        <path fill="#ec6151" d="M1140.6 556.32v37.28h31.05l-2.46-37.28z" />
        <path
          d="M1170.16 571.06l-.97-14.74h-28.59v14.31c6.57 2.62 17.68 5.28 29.56.43z"
          fill="#ea5046"
        />
        <path
          d="M1102.55 482.46s-11.32 80.31 53.28 83.49c57.58 2.84 52.23-64.61 52.59-79.15 0 0-46.72 8.41-71.25-22.05 0 0-9.24 6.2-34.62 17.71z"
          fill="#ec6151"
        />
        <g fill="#ea5046">
          <path d="M1177.54 534.8c-6.27-.6-11.85 4-12.45 10.27-.6 6.27 4 11.85 10.27 12.45 6.27.6 11.85-4 12.45-10.27.6-6.28-4-11.85-10.27-12.45zM1128.48 541.57c.6-6.27-4-11.85-10.27-12.45-3.91-.37-7.55 1.27-9.88 4.09 2.73 6.64 6.54 12.97 11.84 18.26 4.42-1.23 7.85-5.06 8.31-9.9z" />
        </g>
        <path
          d="M1208.42 486.8s15.33-15.76 29.71.2c13.67 15.17-1.42 53.15-34.86 31.74l5.15-31.94z"
          fill="#ec6151"
        />
        <g
          fill="none"
          stroke="#2c2454"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        >
          <path d="M1213.84 495.16s10.45-10.26 22.01 1.64M1220.44 507.68s7.27-3.95 4.88-16.56" />
        </g>
        <path
          d="M1136.46 541.2s17.34 5.79 27.14-4.86"
          fill="none"
          stroke="#2c2454"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        />
        <g>
          <path
            d="M1420.85 641.83s-2.66-15.16-1.28-28.52c1.38-13.36-1.08-21.2 4.15-21.35 5.22-.15 7.83 10.75 8.45 16.74 0 0 6.14-13.98 7.68-24.27s2-15.95 6.14-15.8c4.15.15 6.3 8.12 4.15 21.33 0 0 2.43-6.36 4.35-13.9 2.1-8.22 8.69-6.64 8.7 1.18.01 7.5-1.33 14.93-1.33 14.93s3.18-6.58 5.22-12.55c2.05-5.98 8.7-4.19 7.97 2.02-.92 7.79-2.42 15.2-7.54 35.07 0 0 3.25-3.95 5.4-10.5 2.15-6.55 3.9-10.73 7.11-9.93 4.46 1.12-.25 22.73-4.24 33.48-3.99 10.75-8.7 25.19-8.7 25.19s-24.33 5.62-24.3 5.63c.05 0-21.93-18.75-21.93-18.75z"
            fill="#ec6151"
          />
          <g
            fill="none"
            stroke="#2c2454"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
          >
            <path d="M1439.02 607.73s23.86 4.3 33.9 12.39M1450.13 589.96s-1.56 9.48-4.89 19.1M1461.86 592.16s-1.49 9.7-4.15 20.46M1432.16 608.7l.84 4.73s19.65 4.48 17.13 23.07" />
            <path d="M1419.32 627.48c-.23-4.44-.25-9.42.24-14.17 1.38-13.36-1.08-21.2 4.15-21.35 5.22-.15 7.83 10.75 8.45 16.74 0 0 6.14-13.98 7.68-24.27s2-15.95 6.14-15.8c4.15.15 6.3 8.12 4.15 21.33 0 0 2.43-6.36 4.35-13.9 2.1-8.22 8.69-6.64 8.7 1.18.01 7.5-1.33 14.93-1.33 14.93s3.18-6.58 5.22-12.55c2.05-5.98 8.7-4.19 7.97 2.02-.92 7.79-2.42 15.2-7.54 35.07 0 0 3.25-3.95 5.4-10.5 2.15-6.55 3.9-10.73 7.11-9.93 4.46 1.12-.25 22.73-4.24 33.48" />
          </g>
          <path
            d="M1418.49 652.48l2.36-10.65 46.24 13.11s-3.38 12.6-3.07 12.7l-45.53-15.16z"
            fill="#fff"
          />
          <g>
            <path
              d="M1315.42 716.99c-28.26-28.57-27.65-95.85-54.07-120.43-10.67-9.92-29.26-13.65-47.64-14.57l-61.08 23.46-61.08-21.7c-5.59 1.74-11.11 4.1-16.36 7.28-36.55 22.12-40.66 116.84-40.66 116.84v149.68h224.37l-6.82-94.32c21.16 30.24 52.25 56.54 95.91 54.52 99.84-4.61 122.15-155.87 122.15-155.87l-53.01-13.91c-23.76 98.32-73.44 97.59-101.71 69.02z"
              fill="#fcc45c"
            />
            <path
              d="M1357.88 795.48c-55.74 8.3-105.81-32.24-105.81-32.24 21.16 30.24 52.25 56.54 95.91 54.52 57.14-2.64 88.88-53.3 105.58-96.16-16.11 22.18-53.77 67.64-95.68 73.88zM1058.29 609.31c-21.01 35.17-23.77 98.57-23.77 98.57v105.03c15.27-6.25 33.78-22.04 39.31-61.53 8.05-57.45-8.9-120.46-15.54-142.07z"
              fill="#fab854"
            />
            <path
              d="M1152.63 637.12c46.63 1.73 62.14-38.78 66.43-54.77-1.78-.15-3.57-.26-5.35-.35l-61.08 23.46-61.08-21.7c-1.88.58-3.75 1.25-5.61 1.98 3.45 12.09 18.05 49.58 66.69 51.38z"
              fill="#fff"
            />
            <path
              d="M1091.54 583.75c4.42 12.39 21.08 48.29 65.62 47.53 41.17-.71 53.18-33.79 56.55-49.29-27.16-1.36-53.86 3.41-53.86 3.41s-35.45-11.84-68.31-1.65z"
              fill="#ec6151"
            />
          </g>
        </g>
        <g>
          <path
            d="M1021.73 503.79s.92-13.77 6.55-22.17c4.24-6.32 11.32-7.28 10.24-.9s-5.22 15.16-4.97 23.08h-11.82z"
            fill="#ea5046"
          />
          <path
            d="M1034.52 496.86s3.14-13.44 10.05-20.82c5.2-5.55 12.34-5.36 10.25.76s-7.6 14.12-8.63 21.97l-11.67-1.91z"
            fill="#ea5046"
          />
          <path
            d="M1053.71 570.04s.17-6.35 4.47-15.98 16.79-19.36 15.57-24.17c-1.23-4.81-25.99 5.12-25.99 5.12-14.31-1.33-20.86-13.55-23.68-28.95 0 0 1.69-14.32-4.66-14.12-6.35.2-7.58 30.36-5.53 42.86 2.05 12.49-2.82 22.73-2.82 22.73l42.64 12.51z"
            fill="#ec6151"
          />
          <path
            fill="#fff"
            d="M1011.08 557.55l-3.03 8.14 22.37 12.9h22.33l.96-8.55z"
          />
          <path
            d="M1004.98 563.39s52.28 16.28 50.8 15.21c-1.49-1.08 10.07 56.43 5.98 139.99-4.1 83.56-58.46 101.18-75.37 54.89-24.99-68.41 18.59-210.09 18.59-210.09z"
            fill="#fcc45c"
          />
          <g>
            <path
              d="M1141.13 474.23l26.28 4.98c13.29 2.52 22.38 14.98 20.55 28.38-.03.2-.06.4-.08.6-1.39 9.48-8.11 17.31-17.21 20.32l-25.07 8.29-4.47-62.57z"
              fill="#4f65ad"
            />
            <path
              d="M1135.98 473.78c-2.52 0-4.98.32-7.33.91v-.01c-7.6 1.81-18.99 5.13-33.52 5.13s-25.92-3.33-33.52-5.13v.01c-2.35-.59-4.8-.91-7.33-.91-17.18 0-31.16 14.49-31.16 32.29s13.98 32.29 31.16 32.29c2.52 0 4.98-.32 7.33-.91v.01c7.6-1.81 18.99-5.13 33.52-5.13s25.92 3.33 33.52 5.13v-.01c2.35.59 4.8.91 7.33.91 17.18 0 31.16-14.49 31.16-32.29s-13.98-32.29-31.16-32.29z"
              fill="#2c2454"
            />
            <circle cx={1055.78} cy={506.08} r={25.36} fill="#4f65ad" />
            <circle cx={1055.78} cy={506.08} r={22.67} fill="#ec6151" />
            <circle cx={1055.78} cy={506.08} r={4.76} fill="#14172a" />
            <path
              d="M1098.14 502.4h-6.01c-2.03 0-3.67 1.64-3.67 3.67 0 2.03 1.64 3.67 3.67 3.67h6.01c2.03 0 3.67-1.64 3.67-3.67 0-2.02-1.65-3.67-3.67-3.67z"
              fill="#4f65ad"
            />
            <g>
              <circle cx={1134.49} cy={506.08} r={25.36} fill="#4f65ad" />
              <circle cx={1134.49} cy={506.08} r={22.67} fill="#ec6151" />
              <circle cx={1134.26} cy={506.08} r={4.76} fill="#14172a" />
            </g>
          </g>
          <path
            d="M1056.17 584.27c2 16.39 8.9 66.86 5.59 134.31-4.1 83.56-58.46 101.18-75.37 54.89-2.65-7.26-4.53-15.34-5.77-23.98"
            fill="none"
            stroke="#2c2454"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
          />
        </g>
      </g>
      <g
        fill="none"
        stroke="#2c2454"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      >
        <circle cx={955.88} cy={409.3} r={37.77} />
        <circle
          transform="rotate(-9.23 770.695 755.91)"
          cx={770.69}
          cy={755.91}
          r={19.17}
        />
        <circle cx={481.02} cy={669.9} r={19.17} />
        <path d="M1090.4 387.66c5.11 0 5.11 5.11 10.21 5.11 5.1 0 5.1-5.11 10.21-5.11 5.11 0 5.11 5.11 10.21 5.11 5.11 0 5.11-5.11 10.22-5.11s5.11 5.11 10.22 5.11M700.44 796.76c5.11 0 5.11 5.11 10.21 5.11 5.1 0 5.1-5.11 10.21-5.11 5.11 0 5.11 5.11 10.21 5.11 5.11 0 5.11-5.11 10.22-5.11s5.11 5.11 10.22 5.11M288.72 528.78c5.11 0 5.11 5.11 10.21 5.11 5.1 0 5.1-5.11 10.21-5.11 5.11 0 5.11 5.11 10.21 5.11 5.11 0 5.11-5.11 10.22-5.11s5.11 5.11 10.22 5.11M1260.35 302.42h16.96l-8.48 16.12zM1367.37 612.56h16.96l-8.48 16.13zM762.21 639.16h16.96l-8.48 16.12zM541.45 762.91h16.97l-8.49 16.13z" />
      </g>
    </svg>
  );
}

export default SvgFormBackground2;
